<template>
  <div class="user-form">
    <form @submit.prevent="onFormSubmit">
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("dictionary.general-data") }}</h3>
        </div>
        <div v-if="isLoadingWejoy && (!user.name || user.email)">
          <Loader />
        </div>
        <div v-else>
          <vs-input
            :label-placeholder="$l.get('user-form.name-field', 'capitalize')"
            :danger="isNameInvalid"
            :danger-text="$l.get('errors.input-required', 'capitalize')"
            v-model="user.name"
          />
          <vs-input
            :label-placeholder="$l.get('user-form.email-field', 'capitalize')"
            :danger="isEmailInvalid"
            :danger-text="$l.get('errors.input-required', 'capitalize')"
            v-model="user.email"
          />
        </div>
      </vs-card>

      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("user-form.language-field") }}</h3>
        </div>
        <ul class="centerx">
          <li>
            <vs-radio
              v-model="user.language"
              vs-name="language"
              vs-value="en-US"
              >{{ $l.get("dictionary.languages.english") }}</vs-radio
            >
          </li>
          <li>
            <vs-radio
              v-model="user.language"
              vs-name="language"
              vs-value="pt-BR"
              >{{ $l.get("dictionary.languages.portuguese") }}</vs-radio
            >
          </li>
          <li>
            <vs-radio
              v-model="user.language"
              vs-name="language"
              vs-value="es-ES"
              >{{ $l.get("dictionary.languages.spanish") }}</vs-radio
            >
          </li>
        </ul>
      </vs-card>

      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("user-form.credentials-field") }}</h3>
        </div>
        <div v-if="!!credentials">
          <vs-input
            :label-placeholder="
              $l.get('user-form.username-field', 'capitalize')
            "
            :danger="isUsernameInvalid"
            :danger-text="$l.get('errors.input-required', 'capitalize')"
            v-model="credentials.username"
          />
          <vs-input
            :label-placeholder="
              $l.get('user-form.password-field', 'capitalize')
            "
            v-model="credentials.password"
          />
          <br />
          <vs-button
            color="danger"
            type="border"
            style="width: 100% !important"
            icon-pack="feather"
            icon="icon-trash"
            @click="onRemoveCredentialClick"
            >{{ $l.get("dictionary.remove") }}</vs-button
          >
        </div>
        <div v-else>
          <vs-button
            color="primary"
            type="border"
            style="width: 100% !important"
            icon-pack="feather"
            icon="icon-plus"
            @click="onAddCredentialClick"
            >{{ $l.get("dictionary.add") }}</vs-button
          >
        </div>
      </vs-card>
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("dictionary.raw-data", "capitalize") }}</h3>
        </div>
        <div v-if="user.rawData">
          <vs-button
            color="danger"
            type="border"
            style="width: 100% !important"
            icon-pack="feather"
            icon="icon-trash"
            @click="onRemoveRawDataClick"
            >{{ $l.get("dictionary.remove") }}</vs-button
          >
        </div>
        <div v-else>
          <vs-upload
            :action="uploadAddress"
            fileName="raw"
            :limit="1"
            :show-upload-button="true"
            :automatic="true"
            accept=".txt, .csv"
            :text="$l.get('welcome.upload-area', 'upper')"
            @on-success="onUploadSuccess"
            @on-fail="onUploadFail"
          />
        </div>
      </vs-card>

      <input
        type="submit"
        style="position: absolute; left: -9999px; width: 1px; height: 1px;"
        tabindex="-1"
      />
    </form>

    <vs-card>
      <div slot="header">
        <h3 style="color: #FFA500">WeJoy</h3>
      </div>
      <div v-if="isLoadingWejoy">
        <Loader />
      </div>
      <div v-else>
        <vx-input-group class="mb-base">
          <vs-input
            :label="$l.get('dictionary.email', 'capitalize')"
            placeholder="sample@email.com"
            v-model="wejoyEmail"
            v-on:keyup.enter="onSubmitWejoyEmailSearch"
          />

          <template slot="append">
            <div class="append-text btn-addon">
              <vs-button
                color="primary"
                icon-pack="feather"
                icon="icon-search"
                style="height: 38px; margin-top: 42px;"
                @click="onSubmitWejoyEmailSearch"
              ></vs-button>
            </div>
          </template>
        </vx-input-group>
        <!-- <vs-button
          color="primary"
          type="border"
          style="width: 100% !important"
          icon-pack="feather"
          icon="icon-search"
          @click="onWejoySearchClick"
          v-if="isWejoyQuickSearchVisible"
        >{{ $l.get("dictionary.search") }}: {{ user.email }}</vs-button>-->
        <vs-table :data="user.wejoyUsers">
          <template slot="thead">
            <vs-th>#</vs-th>
            <vs-th>{{ $l.get("dictionary.full-name", "capitalize") }}</vs-th>
            <vs-th>{{ $l.get("dictionary.email", "capitalize") }}</vs-th>
            <vs-th>{{ $l.get("dictionary.type", "capitalize") }}</vs-th>
            <vs-th>{{ $l.get("dictionary.remove", "capitalize") }}</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="idx" v-for="(tr, idx) in data">
              <vs-td :data="tr.wejoyId">{{ tr.wejoyId }}</vs-td>
              <vs-td :data="tr.name">{{ tr.name }}</vs-td>
              <vs-td :data="tr.email">{{ tr.email }}</vs-td>
              <vs-td>
                <feather-icon icon="FacebookIcon" v-if="tr.isFacebook" />
                <feather-icon icon="MailIcon" v-if="!tr.isFacebook" />
                <feather-icon icon="BriefcaseIcon" v-if="tr.isInstitutional" />
              </vs-td>
              <vs-td>
                <vs-button
                  color="primary"
                  type="border"
                  icon="delete"
                  @click="onRemoveWejoyUserClick(idx)"
                ></vs-button>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vs-card>
    <div style="margin-top: 25px; margin-bottom: 25px; text-align: right">
      <vs-button color="primary" type="filled" @click="onFormSubmit()">{{
        $l.get("dictionary.submit-new")
      }}</vs-button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Loader from "@/application/components/Loader";

export default {
  name: "user-form",
  props: ["user", "onSubmit"],
  components: { Loader },
  data() {
    return {
      isNameInvalid: false,
      isEmailInvalid: false,
      isUsernameInvalid: false,
      credentials: null,
      rawDataGateway: null,
      userGateway: null,
      sessionGateway: null,
      isLoadingWejoy: false,
      wejoyEmail: ""
    };
  },
  beforeMount() {
    this.sessionGateway = this.$injector.get("SessionGateway");
    this.rawDataGateway = this.$injector.get("RawDataGateway");
    this.userGateway = this.$injector.get("UserGateway");
  },
  mounted() {
    this.updateWejoyQuickSearch();
    this.updateCredentials();

    const wejoyUserEmail = this.$route.query.wue;
    if (wejoyUserEmail) {
      this.wejoyEmail = wejoyUserEmail;
      this.onSubmitWejoyEmailSearch();
    }
  },
  watch: {
    "user.email": function() {
      this.updateWejoyQuickSearch();
    }
  },
  computed: {
    uploadAddress() {
      return `${this.rawDataGateway.URL}/`;
    }
  },
  methods: {
    updateWejoyQuickSearch() {
      if (!this.user.email) {
        return;
      }
      for (const wejoyUser of this.user.wejoyUsers) {
        if (wejoyUser.email == this.user.email) {
          return;
        }
      }
      this.wejoyEmail = this.user.email;
    },
    updateCredentials() {
      if (this.user.credentials && this.user.credentials.length > 0) {
        this.credentials = this.user.credentials[0];
      } else {
        this.credentials = null;
      }
    },
    onFormSubmit() {
      const credentials = this.credentials;
      this.isNameInvalid = this.user.name.length == 0;
      this.isEmailInvalid = this.user.email.length == 0;
      if (credentials) {
        this.isUsernameInvalid = credentials.username.length == 0;
      }
      if (this.isNameInvalid || this.isEmailInvalid || this.isUsernameInvalid) {
        return;
      } else {
        for (const wejoyUser of this.user.wejoyUsers) {
          delete wejoyUser.id;
        }
        this.onSubmit();
      }
    },
    onUploadSuccess(data) {
      let rawData = JSON.parse(data.target.responseText);
      this.user.rawData = rawData.id;
      // console.log(rawData);
      this.$forceUpdate();
      //   this.interactors.uploadRawDataSuccess.handle(rawData);
    },
    onUploadFail(data) {
      return;
    },
    onAddCredentialClick() {
      if (!this.user.credentials) {
        this.user.credentials = [];
      }
      this.user.credentials.push({
        username: "",
        password: ""
      });
      this.updateCredentials();
    },
    onRemoveCredentialClick() {
      this.user.credentials.splice(0, 1);
      this.updateCredentials();
    },
    onRemoveRawDataClick() {
      this.user.rawData = null;
    },
    onRemoveWejoyUserClick(idx) {
      this.user.wejoyUsers.splice(idx, 1);
    },
    async onSubmitWejoyEmailSearch() {
      var session = this.sessionGateway.get();
      this.isLoadingWejoy = true;
      let userURL = this.userGateway.ROOT_URL;
      let response = await axios.get(`${userURL}/wejoy/`, {
        params: { e: this.wejoyEmail },
        headers: session.tokens.getHeader()
      });
      for (const wejoyUser of response.data) {
        if (!this.user.name) {
          this.user.name = wejoyUser.name;
        }
        if (!this.user.email) {
          this.user.email = wejoyUser.email;
        }

        wejoyUser.wejoyId = wejoyUser.id;
        this.user.wejoyUsers.splice(0, 0, wejoyUser);
      }
      this.wejoyEmail = "";
      this.isLoadingWejoy = false;
    }
  }
};
</script>

<style lang="scss">
.user-form .vs-con-input-label {
  width: 100% !important;
  margin-top: 25px;
}

.user-form .criteria-node {
  cursor: pointer;
  padding: 0 2px;
  min-width: 60px;
  text-align: center;
  font-size: 11px;
  height: 30px;
  border: 2px solid #e8e8e3;
  border-radius: 2px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  background: #fff;
}

.user-form .criteria-node:active {
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1);
  margin: 1px 0 0 1px;
  border: 2px solid #d3d3cb;
}

.user-form .criteria-node .node-name {
  line-height: 30px;
  color: #9b9b9b;
}

.user-form .criteria-node:hover .node-name {
  color: #8b8b8b;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.15);
}

// .user-form .vs-input {
//   margin: 15px;
// }

.user-form .con-img-upload .con-input-upload {
  width: 100% !important;
  margin: 5px;
  // background: rgba(var(--vs-primary), 0.15) !important;
  box-shadow: 0px 5px 5px rgba(var(--vs-primary), 0.5);
}
.user-form .con-img-upload .con-input-upload:hover {
  box-shadow: 0px 10px 5px rgba(var(--vs-primary), 0.5);
}
.user-form .con-img-upload .con-input-upload:active {
  box-shadow: 0px 1px 5px rgba(var(--vs-primary), 0.5);
}

.user-form .con-img-upload .text-input {
  font-size: 12px;
  margin-top: -24px !important;
}

.user-form .con-img-upload .img-upload {
  display: none;
}
.user-form .con-img-upload .on-progress-all-upload {
  opacity: 1 !important;
}
.user-form .con-img-upload .disabled-upload {
  height: 4px !important;
}
</style>
