<template>
  <div>
    <Loader v-if="isLoading" />
    <div v-else>
      <h3 style="margin-bottom: 25px">{{ $l.get("user-create.header") }}</h3>
      <UserForm :user="user" :onSubmit="onSubmit" />
    </div>
  </div>
</template>

<script>
import SubmitFormInteractor from "@/business/user-create/submit-form";
import InitUserCreateInteractor from "@/business/user-create/init-user-create";
import UserCreateScreenController from "@/adapters/controllers/screen-user-create";
import UserForm from "@/application/components/users/UserForm";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "user-create",
  components: { UserForm, Loader },
  data() {
    return {
      controller: null,
      interactors: {
        submitForm: null,
        initUserCreate: null
      },
      data: null,
      isLoading: false,
      user: {
        name: "",
        email: "",
        language: "en-US",
        wejoyUsers: []
      }
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(UserCreateScreenController);

    //{ INTERACTORS
    this.interactors.initUserCreate = this.$injector.get(
      InitUserCreateInteractor
    );
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    this.interactors.initUserCreate.handle();
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmit() {
      this.interactors.submitForm.handle(this.user);
    }
  }
};
</script>

<style lang="scss"></style>
